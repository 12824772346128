<template>
  <div id="app">
    <h1>24小时在线 实时追溯问题</h1>
    <p>200+IDC监控点 4.5+W路由器</p>

    <!-- 刷新提示和手动刷新按钮 -->
    <div class="time-cell" >
      数据将在 {{ countdown }} 秒后刷新...
      <button @click="manualRefresh">立即刷新</button>
    </div>

    <h2 >测速排名</h2>
    <div class="table-container">
    <!-- <table class="speed-table"> -->
      <table >
      <thead>
        <tr >
          <th class="time-cell">排名</th>
          <th class="time-cell">域名</th>
          <th class="time-cell">时间</th>
        </tr>
      </thead>
   
      <tbody>

  <tr v-for="item in speedData" :key="item.rank" >
  <td class="time-cell">  {{ item.rank }}</td>
  
  <td class="time-cell">
  <a 
    :href="'http://' + item.domain" 
    target="_blank"  
  >
 
    {{ item.domain }}
  </a>
  </td>

    <td style="color: var(--time-color)" class="time-cell">
      {{ item.time }}
   </td>
</tr>
</tbody>
    </table>
       </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      speedData: [], // 测速数据
      countdown: 60, // 刷新倒计时
      timer: null, // 定时器
      partners: [ /* ... */ ],
      friendLinks: [ /* ... */ ],
    };
  },
  methods: {
    getTimeClass(timeStr) {
      const time = parseFloat(timeStr.replace('s', ''));
      if (time <= 0.5) return 'fast';
      if (time <= 1.0) return 'medium';
      return 'slow';
    },
    async fetchSpeedData() {
      try {
        //const response = await fetch('http://localhost:8888/speed-data');
        const response = await fetch('/api/speed-data');
        this.speedData = await response.json();
      } catch (error) {
        console.error('Error fetching speed data:', error);
      }
    },
    // 启动倒计时
    startCountdown() {
      this.countdown = 60; // 重置倒计时
      this.timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.timer);
          this.fetchSpeedData(); // 刷新数据
          this.startCountdown(); // 重新开始倒计时
        }
      }, 1000);
    },
    manualRefresh() {
      clearInterval(this.timer); // 清除当前定时器
      this.fetchSpeedData(); // 立即刷新数据
      this.startCountdown(); // 重新启动倒计时
    },
  },
  mounted() {
    this.fetchSpeedData();
    this.startCountdown();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&display=swap');
/* 统一使用标准CSS语法 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin: 0;
  padding: 20px;
  min-height: 100vh;
  background: 

    url('@/assets/price-bg.png') center/cover no-repeat fixed;
     /* 增强透明元素的可读性 */
  backdrop-filter: blur(2px); /* 毛玻璃效果 */
  color: rgba(0, 0, 0, 0.8);  /* 深色文字保证可读性 */
}
/* 狂草字体样式 */
.time-cell {
  font-family: 'Ma Shan Zheng', cursive; /* 狂草字体 */
  color: white !important; /* 强制白色 */
  font-size: 1.2em; /* 放大字号 */
  text-shadow: 
    0 0 5px rgba(0,0,0,0.8), /* 深色阴影增强对比 */
    0 0 10px currentColor; /* 发光效果 */
  letter-spacing: 1px; /* 字间距 */
  font-weight: normal; /* 禁用默认加粗 */
  transform: rotate(-1deg); /* 轻微倾斜 */
 
}

/* 表格容器 */
.table-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1000px;

   /* 背景图片设置 */
   background-image: url('@/assets/url-bg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  /* 添加半透明遮罩 */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.speed-table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(255, 255, 255, 0.85); /* 半透明白色背景 */
  backdrop-filter: blur(2px);
}




/* 响应式设计 */
@media (max-width: 768px) {
  .table-container {
    background-size: contain;
  }
}

/* 速度等级颜色 */
.fast, .fast a { background-color: #d4edda; color: #155724; }
.medium, .medium a { background-color: #fff3cd; color: #856404; }
.slow, .slow a { background-color: #f8d7da; color: #721c24; }

/* 超链接悬停时保持颜色一致 */
.fast a:hover, 
.medium a:hover, 
.slow a:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.refresh-tip {
  margin: 20px 0;
  font-size: 14px;
  color: #666;
}

.refresh-tip button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.refresh-tip button:hover {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px auto;
}



th, td {
  background-color: rgba(255,255,255,0.3); /* 半透明白色衬底 */
  backdrop-filter: blur(2px); /* 毛玻璃效果 */
  border: 1px solid #ddd;
  padding: 13px;

}


th {
  background-color: #f2f2f2;
}

td:nth-child(3) {
  min-width: 100px;
}

/* 超链接样式 */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* 合作伙伴和友情链接的样式 */
.partner-links, .friend-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.partner-item, .friend-item {
  padding: 10px 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease;
}

.partner-item:hover, .friend-item:hover {
  background-color: #e9ecef;
  cursor: pointer;
}
</style>